@import 'variables';

.modal-backdrop{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 123;
  width: 100%;
  height: 100%;
  background-color: $black;
  opacity: 0.3;
  transition: .4s;
}
.block-modal{
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 125;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all .4s;
  text-align: center;

  &.modal-open{
    opacity: 1;

    .content{
      transform: scale(1);
      opacity: 1;
    }
  }

  .content{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 700px;
    max-width: 90%;
    margin: 5% auto;
    background-color: $darkgray;
    box-shadow: 0 0 35px rgba(0,0,0,0.2);
    transform: scale(0.8);
    opacity: 0;
    transition-delay: .2s;
    transition: all .6s;
    text-align: left;

    .cross{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 5;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 1s;

      &::before,&::after{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        content: '';
        background-color: $theme;
        transform: rotate(45deg);
        transition: all .4s;
      }

      &::before{
        width: 90%;
        height: 2px;
      }
      &::after{
        width: 2px;
        height: 90%;
      }

      &:hover{
        &::before,&::after{
          opacity: 0.8;
        }
        &::before{
          width: 80%;
        }
        &::after{
          height: 80%;
        }
      }
    }
  }
  .data-block{
    padding: 3rem 2rem;
  }
  h4{
    text-transform: uppercase;
    text-align: center;
  }

  .wpforms-recaptcha-container .grecaptcha-badge {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }
}
