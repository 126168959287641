@charset "UTF-8";
body, h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Source Sans Pro', sans-serif;
}

.block-gb-parallaximage figure img, .block-gb-textimage .data-image figure img, .block-banner figure img, .block-archive figure img, .block-book figure img, .block-blog figure img, .block-article figure img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.btn, .form-block div.wpforms-container-full .wpforms-form button[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] {
  color: #ffffff;
  background-color: #511575;
  background-position: bottom 0 left 0;
  background-size: 202% 100%;
  border: solid 1px #511575;
  border-radius: 0;
  outline: none;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  padding: .8rem 2rem;
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  transition: all .4s ease-in-out;
}

.btn:hover, .form-block div.wpforms-container-full .wpforms-form button:hover[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button:hover[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button:hover[type=submit] {
  color: #ffffff;
  background-color: transparent;
  background-position: bottom 0 right 0;
  border-color: #511575;
}

.btn img, .form-block div.wpforms-container-full .wpforms-form button[type=submit] img, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] img, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] img, .btn svg, .form-block div.wpforms-container-full .wpforms-form button[type=submit] svg, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] svg, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] svg {
  position: relative;
  bottom: 2px;
  width: auto;
  height: 12px;
  margin-left: 5px;
}

.btn img:first-child, .form-block div.wpforms-container-full .wpforms-form button[type=submit] img:first-child, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] img:first-child, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] img:first-child, .btn svg:first-child, .form-block div.wpforms-container-full .wpforms-form button[type=submit] svg:first-child, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] svg:first-child, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] svg:first-child {
  margin-left: 0;
  margin-right: 5px;
}

.btn i, .form-block div.wpforms-container-full .wpforms-form button[type=submit] i, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] i, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] i {
  margin-right: 5px;
}

.btn i svg, .form-block div.wpforms-container-full .wpforms-form button[type=submit] i svg, .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit] i svg, .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] i svg {
  height: 1rem;
}

.btn-lite {
  color: #511575;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-lite:hover {
  color: #511575;
  background-color: transparent;
  border-color: #ffffff;
}

.btn-radius {
  border-radius: 4rem;
}

.btn-skewed {
  transform: skew(-10deg);
}

.btn-border, .form-block div.wpforms-container-full .wpforms-form button[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit] {
  border-color: #511575;
  background-color: transparent;
  color: #000000;
}

.btn-border:hover, .form-block div.wpforms-container-full .wpforms-form button:hover[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button:hover[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button:hover[type=submit] {
  border-color: #511575;
  background-color: #511575;
  color: #ffffff;
}

.btn-lite.btn-border, .form-block div.wpforms-container-full .wpforms-form button.btn-lite[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button.btn-lite[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button.btn-lite[type=submit] {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}

.btn-lite.btn-border:hover, .form-block div.wpforms-container-full .wpforms-form button.btn-lite:hover[type=submit],
.block-wysiwyg div.wpforms-container-full .wpforms-form button.btn-lite:hover[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form button.btn-lite:hover[type=submit] {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #511575;
}

.btn-primary {
  background-color: transparent;
  background-image: linear-gradient(to right, #511575 50%, transparent 50%);
  background-position: bottom 0 left 0;
}

.btn-primary:hover {
  background-color: transparent;
  background-position: bottom 0 right 0;
}

.btn-dark {
  border-color: #262626;
  background-image: linear-gradient(to right, #262626 50%, transparent 50%);
}

.btn-dark:hover {
  border-color: #262626;
}

.btn-arrow {
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn-arrow svg {
  height: 1.2rem;
  width: auto;
  display: block;
}

@media (min-width: 575px) {
  .btn-arrow svg {
    height: 1.4rem;
  }
}

@media (min-width: 768px) {
  .btn-arrow svg {
    height: 1.6rem;
  }
}

.btn-arrow svg polyline {
  stroke: #511575;
}

.arrow-black svg polyline {
  stroke: #000000;
}

.btn-text {
  font-size: .9rem;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  color: #000000;
  position: relative;
  transition: .4s;
}

.btn-angle {
  display: inline-block;
  vertical-align: middle;
}

.btn-angle svg {
  height: 1.2rem;
  width: auto;
  display: block;
}

@media (min-width: 575px) {
  .btn-angle svg {
    height: 1.4rem;
  }
}

@media (min-width: 768px) {
  .btn-angle svg {
    height: 1.6rem;
  }
}

.btn-angle svg polyline {
  stroke: #511575;
}

.field-block, .form-block div.wpforms-container-full .wpforms-form,
.block-wysiwyg div.wpforms-container-full .wpforms-form, .form-contact-block div.wpforms-container-full .wpforms-form {
  position: relative;
  margin-bottom: 1rem;
}

.field-block input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .form-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .block-wysiwyg div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .form-contact-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .field-block textarea, .form-block div.wpforms-container-full .wpforms-form textarea, .block-wysiwyg div.wpforms-container-full .wpforms-form textarea, .form-contact-block div.wpforms-container-full .wpforms-form textarea, .field-block select, .form-block div.wpforms-container-full .wpforms-form select, .block-wysiwyg div.wpforms-container-full .wpforms-form select, .form-contact-block div.wpforms-container-full .wpforms-form select {
  width: 100%;
  border: solid 1px #dadce0;
  border-radius: 0;
  padding: .5rem;
  height: 3rem;
  font-size: .9rem;
  font-weight: 400;
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: .4s;
}

.field-block input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder, .form-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder, .block-wysiwyg div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder, .form-contact-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder, .field-block textarea::placeholder, .form-block div.wpforms-container-full .wpforms-form textarea::placeholder, .block-wysiwyg div.wpforms-container-full .wpforms-form textarea::placeholder, .form-contact-block div.wpforms-container-full .wpforms-form textarea::placeholder, .field-block select::placeholder, .form-block div.wpforms-container-full .wpforms-form select::placeholder, .block-wysiwyg div.wpforms-container-full .wpforms-form select::placeholder, .form-contact-block div.wpforms-container-full .wpforms-form select::placeholder {
  color: #000000;
}

.field-block input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, .form-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, .block-wysiwyg div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, .form-contact-block div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, .field-block textarea:focus, .form-block div.wpforms-container-full .wpforms-form textarea:focus, .block-wysiwyg div.wpforms-container-full .wpforms-form textarea:focus, .form-contact-block div.wpforms-container-full .wpforms-form textarea:focus, .field-block select:focus, .form-block div.wpforms-container-full .wpforms-form select:focus, .block-wysiwyg div.wpforms-container-full .wpforms-form select:focus, .form-contact-block div.wpforms-container-full .wpforms-form select:focus {
  border-color: #dadce0;
}

.field-block select, .form-block div.wpforms-container-full .wpforms-form select, .block-wysiwyg div.wpforms-container-full .wpforms-form select, .form-contact-block div.wpforms-container-full .wpforms-form select {
  background-image: url("../images/icon-sort.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1;
}

.field-block textarea, .form-block div.wpforms-container-full .wpforms-form textarea, .block-wysiwyg div.wpforms-container-full .wpforms-form textarea, .form-contact-block div.wpforms-container-full .wpforms-form textarea,
.field-block textarea.wpforms-field-large,
.form-block div.wpforms-container-full .wpforms-form textarea.wpforms-field-large,
.block-wysiwyg div.wpforms-container-full .wpforms-form textarea.wpforms-field-large,
.form-contact-block div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
  height: 6rem;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .field-block textarea, .form-block div.wpforms-container-full .wpforms-form textarea, .block-wysiwyg div.wpforms-container-full .wpforms-form textarea, .form-contact-block div.wpforms-container-full .wpforms-form textarea,
  .field-block textarea.wpforms-field-large,
  .form-block div.wpforms-container-full .wpforms-form textarea.wpforms-field-large,
  .block-wysiwyg div.wpforms-container-full .wpforms-form textarea.wpforms-field-large,
  .form-contact-block div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
    height: 7rem;
  }
}

.field-check li, .form-block div.wpforms-container-full .wpforms-form li, .block-wysiwyg div.wpforms-container-full .wpforms-form li {
  position: relative;
  margin-bottom: 1rem !important;
}

.field-check li input[type=radio], .form-block div.wpforms-container-full .wpforms-form li input[type=radio], .block-wysiwyg div.wpforms-container-full .wpforms-form li input[type=radio],
.field-check li input[type=checkbox],
.form-block div.wpforms-container-full .wpforms-form li input[type=checkbox],
.block-wysiwyg div.wpforms-container-full .wpforms-form li input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  appearance: none;
  border: none;
  height: 20px;
  width: 20px;
}

.field-check li label, .form-block div.wpforms-container-full .wpforms-form li label, .block-wysiwyg div.wpforms-container-full .wpforms-form li label {
  padding-left: 25px;
}

.field-check li label::before, .form-block div.wpforms-container-full .wpforms-form li label::before, .block-wysiwyg div.wpforms-container-full .wpforms-form li label::before, .field-check li label::after, .form-block div.wpforms-container-full .wpforms-form li label::after, .block-wysiwyg div.wpforms-container-full .wpforms-form li label::after {
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transition: .4s;
}

.field-check li label::before, .form-block div.wpforms-container-full .wpforms-form li label::before, .block-wysiwyg div.wpforms-container-full .wpforms-form li label::before {
  border: solid 1px #511575;
}

.field-check li label::after, .form-block div.wpforms-container-full .wpforms-form li label::after, .block-wysiwyg div.wpforms-container-full .wpforms-form li label::after {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: 14px;
  height: 14px;
  background-color: #511575;
  transform: scale(0);
  opacity: 0;
}

.field-check li label div, .form-block div.wpforms-container-full .wpforms-form li label div, .block-wysiwyg div.wpforms-container-full .wpforms-form li label div {
  display: inline-block;
  padding-left: .5rem;
  margin-top: -4px;
}

.field-check li label div b, .form-block div.wpforms-container-full .wpforms-form li label div b, .block-wysiwyg div.wpforms-container-full .wpforms-form li label div b {
  font-size: 1rem;
}

.field-check li label div span, .form-block div.wpforms-container-full .wpforms-form li label div span, .block-wysiwyg div.wpforms-container-full .wpforms-form li label div span {
  font-size: .9rem;
}

.field-check li input[type=radio]:checked + label::after, .form-block div.wpforms-container-full .wpforms-form li input[type=radio]:checked + label::after, .block-wysiwyg div.wpforms-container-full .wpforms-form li input[type=radio]:checked + label::after,
.field-check li input[type=checkbox]:checked + label::after,
.form-block div.wpforms-container-full .wpforms-form li input[type=checkbox]:checked + label::after,
.block-wysiwyg div.wpforms-container-full .wpforms-form li input[type=checkbox]:checked + label::after {
  transform: scale(1);
  opacity: 1;
}

.wpcf7-not-valid-tip {
  font-size: .9rem;
  margin-top: 5px;
}

.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 123;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  transition: .4s;
}

.block-modal {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 125;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all .4s;
  text-align: center;
}

.block-modal.modal-open {
  opacity: 1;
}

.block-modal.modal-open .content {
  transform: scale(1);
  opacity: 1;
}

.block-modal .content {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 700px;
  max-width: 90%;
  margin: 5% auto;
  background-color: #303030;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  opacity: 0;
  transition-delay: .2s;
  transition: all .6s;
  text-align: left;
}

.block-modal .content .cross {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 1s;
}

.block-modal .content .cross::before, .block-modal .content .cross::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  content: '';
  background-color: #511575;
  transform: rotate(45deg);
  transition: all .4s;
}

.block-modal .content .cross::before {
  width: 90%;
  height: 2px;
}

.block-modal .content .cross::after {
  width: 2px;
  height: 90%;
}

.block-modal .content .cross:hover::before, .block-modal .content .cross:hover::after {
  opacity: 0.8;
}

.block-modal .content .cross:hover::before {
  width: 80%;
}

.block-modal .content .cross:hover::after {
  height: 80%;
}

.block-modal .data-block {
  padding: 3rem 2rem;
}

.block-modal h4 {
  text-transform: uppercase;
  text-align: center;
}

.block-modal .wpforms-recaptcha-container .grecaptcha-badge {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
}

.block-pagination {
  margin-top: 2rem;
}

.block-pagination a,
.block-pagination span {
  height: 2rem;
  line-height: 2rem;
  border: solid 1px #dadce0;
  padding: 0 .5rem;
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: #000000;
}

.block-gb .data-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.block-gb .data-row-reverse {
  flex-direction: row-reverse;
}

.block-gb .data-col {
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.block-gb .data-col-1,
.block-gb .data-col-2,
.block-gb .data-col-3,
.block-gb .data-col-4,
.block-gb .data-col-5,
.block-gb .data-col-6,
.block-gb .data-col-7,
.block-gb .data-col-8,
.block-gb .data-col-9,
.block-gb .data-col-10,
.block-gb .data-col-11,
.block-gb .data-col-12 {
  width: 100%;
}

@media (min-width: 768px) {
  .block-gb .data-col-4 {
    width: 33.333333%;
  }
}

@media (min-width: 768px) {
  .block-gb .data-col-6 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .block-gb .data-col-8 {
    width: 66.666666%;
  }
}

.block-gb-parallaximage {
  padding: 2rem 0;
}

.block-gb-parallaximage figure {
  position: relative;
  z-index: 0;
  width: 100%;
  padding-bottom: 40%;
  overflow: hidden;
  margin: 0;
}

.block-gb-textimage {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .block-gb-textimage.imagealign-bottom {
    margin-left: 5%;
    margin-right: 10%;
  }
}

@media (min-width: 992px) {
  .block-gb-textimage.imagealign-bottom {
    margin-left: 10%;
    margin-right: 20%;
  }
}

.block-gb-textimage.imagealign-bottom .data-image {
  margin-top: 2rem;
}

.block-gb-textimage.imagealign-bottom .data-image figure {
  position: relative;
  left: 0;
  right: 0;
  height: auto;
  padding-bottom: 0;
}

.block-gb-textimage.imagealign-bottom .data-image figure img {
  width: 100%;
  position: relative;
  object-fit: initial;
}

.block-gb-textimage .data-firstletter p:first-child::first-letter {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1;
}

.block-gb-textimage .data-firstletter + .data-col-right {
  margin-top: 4rem;
}

.block-gb-textimage .data-image figure {
  width: 100%;
  height: auto;
  padding-bottom: 50%;
  margin: 0 0 1.5rem;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .block-gb-textimage .data-image figure {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    margin: auto;
    z-index: 0;
    width: auto;
    height: 100%;
    padding-bottom: 0;
  }
}

.block-gb-textimage .data-col-left p:last-child,
.block-gb-textimage .data-col-right p:last-child {
  margin: 0;
}

.block-gb-quote {
  margin: 0 0 1rem;
  padding: 2rem 0 0 3rem;
  font-style: normal;
  position: relative;
}

.block-gb-quote::before {
  content: "“";
  font-size: 7rem;
  font-style: normal;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  color: #511575;
}

.block-gb-quote cite::before {
  width: 3rem;
  height: 2px;
  content: '';
  display: block;
  background-color: #511575;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.block-gb-quote cite b {
  font-style: normal;
}

.background-page {
  background-color: #e87024;
  color: #000000;
}

@media (min-width: 768px) {
  .block-inner {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 992px) {
  .block-inner {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.block-h {
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
}

.block-h1 {
  font-size: 5rem;
}

.block-h2 {
  font-size: 4rem;
}

.block-h3 {
  font-size: 3rem;
}

.block-h4 {
  font-size: 2rem;
}

.block-h4 span {
  color: #262626;
}

.block-h5 {
  font-size: 1.5rem;
}

.block-hos {
  width: 100%;
  height: 4rem;
}

@media (min-width: 575px) {
  .block-hos {
    height: 6rem;
  }
}

.block-line {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .block-line {
    margin-top: 0;
  }
}

.block-line::after {
  display: block;
  width: 3rem;
  height: 3px;
  content: '';
  background-color: #511575;
}

.line-vr::after {
  width: 3px;
  height: 3rem;
}

body div.wpcf7-response-output {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

hr {
  background-color: #dadce0;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999999;
}

.relative {
  position: relative;
}

.hos {
  position: absolute;
  top: 0;
  left: 0;
}

.wp-block-audio audio {
  outline: none;
}

.block-mobile-nav {
  background-color: #000000;
  color: #000000;
  font-weight: 400;
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -260px;
  z-index: 321;
  transition: 0.4s;
}

.block-mobile-nav.active {
  right: 0;
}

.block-mobile-nav .contain {
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 1rem 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.block-mobile-nav a {
  color: #cccccc;
  transition: .4s;
}

.block-mobile-nav a:hover {
  color: #ffffff;
}

.block-mobile-nav nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.block-mobile-nav nav ul li {
  position: relative;
  margin: 0;
  padding: 0.5rem 0;
}

.block-mobile-nav nav ul li ul {
  display: none;
  margin-left: 0.5rem;
}

.block-mobile-nav nav ul li ul li {
  padding: 5px 0;
}

.block-mobile-nav nav ul li ul li i {
  top: 0.2rem;
}

.block-mobile-nav nav ul li ul li a {
  position: relative;
  padding-left: 15px;
  text-transform: none;
}

.block-mobile-nav nav ul li ul li a::before {
  width: 10px;
  height: 2px;
  content: '';
  position: absolute;
  top: 0.6rem;
  left: 0;
  background-color: #ffffff;
}

.block-mobile-nav nav .active > i::after {
  height: 0;
}

.block-mobile-nav nav i {
  cursor: pointer;
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  margin: auto;
  z-index: 4;
}

.block-mobile-nav nav i::after, .block-mobile-nav nav i::before {
  width: 50%;
  height: 2px;
  content: '';
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: 0.4s;
}

.block-mobile-nav nav i::after {
  width: 2px;
  height: 50%;
}

.block-mobile-nav nav a {
  display: inline-block;
  margin-right: 2rem;
  color: #ffffff;
  font-size: .9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.block-mobile-nav .search-block {
  position: relative;
  margin-top: 1.5rem;
}

.block-mobile-nav .search-block input {
  width: 100%;
  height: 2.4rem;
  background-color: #ffffff;
  border: none;
  transition: 0.4s;
  border-radius: 4rem;
  font-size: 0.9rem;
  padding-left: 1rem;
  color: #000000;
}

.block-mobile-nav .search-block button {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
}

.block-mobile-nav .search-block button svg {
  width: 50%;
  height: 50%;
  margin: 25%;
}

.block-mobile-nav .search-block button svg path {
  fill: #000000;
}

.block-mobile-nav address {
  margin: auto 0 0 0;
  padding: 2rem 0 3rem;
  font-style: normal;
}

.block-mobile-nav address p {
  border-top: solid 1px #dadce0;
  font-weight: 300;
  padding: 1.5rem 0 0;
}

.block-mobile-nav address .icon-row {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.block-mobile-nav address .icon-row .icon-block {
  position: absolute;
  top: 5px;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.block-mobile-nav address .icon-row .icon-block svg {
  display: block;
  width: 100%;
  height: 100%;
}

.block-mobile-nav address .icon-row .icon-block svg path {
  fill: #cccccc;
}

.block-mobile-nav address .social-block {
  display: block;
  text-align: center;
  border-top: solid 1px #dadce0;
  padding: 1.5rem 0 0 0;
  margin: 1.5rem 2rem 0;
}

@media (min-width: 1200px) {
  .block-mobile-nav address .social-block {
    display: none;
  }
}

.block-mobile-nav address .social-block a {
  color: #000000;
  height: 20px;
  margin: 0 .5rem;
  font-size: 1.2rem;
}

.block-mobile-nav address .social-block a:hover {
  color: #ffffff;
}

.block-wrapper {
  width: 100%;
  overflow: hidden;
}

.block-wrapper.active .block-ui {
  right: 260px;
}

@media (min-width: 1200px) {
  .block-wrapper.active .block-ui {
    right: 0;
  }
}

.block-wrapper .block-ui {
  position: relative;
  right: 0;
  transition: .4s;
}

.block-wysiwyg h2 {
  text-transform: none;
}

.block-table {
  width: 100%;
  text-align: left;
  border: solid 1px #dadce0 !important;
  margin-bottom: 2rem;
}

.block-table td {
  padding: 7px 10px !important;
  border-top: solid 1px #dadce0 !important;
}

.block-table tr:first-child td {
  background-color: #262626 !important;
  color: #511575 !important;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-summary td:nth-child(1) {
  width: 25%;
}

.table-summary td:nth-child(2) {
  width: 60%;
}

.table-summary td:nth-child(3) {
  width: 25%;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.mejs__overlay-button {
  position: relative;
  background-color: rgba(219, 53, 42, 0.4) !important;
  border-radius: 50%;
  background-image: none;
  outline: none;
}

.mejs__overlay-button::before {
  width: 60%;
  height: 60%;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(219, 53, 42, 0.8);
  transition: .4s;
}

.mejs__overlay-button::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #ffffff;
}

.mejs__overlay-button:hover::before {
  animation: rounscale 2s ease-in-out infinite;
}

@keyframes rounscale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

body div.wpforms-container-full .wpforms-form .wpforms-field-html ul, body div.wpforms-container-full .wpforms-form .wpforms-field-html ol {
  margin-left: 1rem !important;
}

body div.wpforms-container-full .wpforms-form .wpforms-field-html .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

body div.wpforms-container-full .wpforms-form .wpforms-field-html .col-12 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.form-block .wpforms-container,
.block-wysiwyg .wpforms-container {
  text-align: left;
}

.form-block .wpforms-container .block-line,
.block-wysiwyg .wpforms-container .block-line {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

@media (min-width: 768px) {
  .form-block .wpforms-container .block-line,
  .block-wysiwyg .wpforms-container .block-line {
    margin-top: 0;
  }
}

.form-block .wpforms-container h2,
.block-wysiwyg .wpforms-container h2 {
  margin-bottom: 1.5rem !important;
}

.form-block .wpforms-container .block-table tr:first-child td,
.block-wysiwyg .wpforms-container .block-table tr:first-child td {
  color: #000000 !important;
}

.form-block div.wpforms-container-full,
.block-wysiwyg div.wpforms-container-full {
  margin-bottom: 0;
}

.form-block div.wpforms-container-full .wpforms-form h2,
.block-wysiwyg div.wpforms-container-full .wpforms-form h2 {
  font-size: 1.3rem;
}

@media (min-width: 575px) {
  .form-block div.wpforms-container-full .wpforms-form h2,
  .block-wysiwyg div.wpforms-container-full .wpforms-form h2 {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) {
  .form-block div.wpforms-container-full .wpforms-form h2,
  .block-wysiwyg div.wpforms-container-full .wpforms-form h2 {
    font-size: 1.6rem;
  }
}

@media (min-width: 992px) {
  .form-block div.wpforms-container-full .wpforms-form h2,
  .block-wysiwyg div.wpforms-container-full .wpforms-form h2 {
    font-size: 1.8rem;
  }
}

.form-block div.wpforms-container-full .wpforms-form .wpforms-field-label,
.block-wysiwyg div.wpforms-container-full .wpforms-form .wpforms-field-label {
  font-size: 1.1rem;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 1rem;
}

@media (min-width: 575px) {
  .form-block div.wpforms-container-full .wpforms-form .wpforms-field-label,
  .block-wysiwyg div.wpforms-container-full .wpforms-form .wpforms-field-label {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  .form-block div.wpforms-container-full .wpforms-form .wpforms-field-label,
  .block-wysiwyg div.wpforms-container-full .wpforms-form .wpforms-field-label {
    font-size: 1.2rem;
  }
}

.form-block div.wpforms-container-full .wpforms-form,
.block-wysiwyg div.wpforms-container-full .wpforms-form {
  margin-bottom: 0;
}

.form-block div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container,
.block-wysiwyg div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
  padding: 0;
}

.form-block div.wpforms-container-full .wpforms-form .wpforms-submit-container,
.block-wysiwyg div.wpforms-container-full .wpforms-form .wpforms-submit-container {
  text-align: center;
}

.form-block .grecaptcha-badge,
.block-wysiwyg .grecaptcha-badge {
  z-index: 97;
}

.form-contact-block {
  text-align: left;
}

.form-contact-block div.wpforms-container-full .wpforms-form a {
  color: #ffffff;
}

.form-contact-block div.wpforms-container-full {
  margin-bottom: 0;
}

.form-contact-block div.wpforms-container-full .wpforms-form .wpforms-field-label {
  font-size: 1.1rem;
  font-weight: 500;
}

.form-contact-block div.wpforms-container-full .wpforms-form label.wpforms-error {
  margin-top: .5rem;
}

.form-contact-block div.wpforms-container-full .wpforms-form {
  margin-bottom: 0;
}

.form-contact-block div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
  padding: 0;
}

.form-contact-block div.wpforms-container-full .wpforms-form .wpforms-submit-container {
  text-align: center;
}

.form-contact-block .grecaptcha-badge {
  z-index: 97;
}

.pf-control-bar-button g,
.pf-control-bar-pager g {
  fill: #000000;
}

#pf-pagerin {
  color: #000000;
}

.block-owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translateY(-50%);
  margin: auto;
  height: 1px;
}

.block-owl-nav button {
  border: solid 1px #511575;
  background-color: transparent;
  color: #511575;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: .4s;
}

.block-owl-nav button svg {
  width: 60%;
  height: 60%;
  display: block;
  margin: 20%;
}

.block-owl-nav button svg polygon {
  fill: #511575;
  transition: .4s;
}

.block-owl-nav button:hover {
  border-color: #000000;
}

.block-owl-nav button:hover svg polygon {
  fill: #000000;
}

.ngg-gallery-thumbnail a img {
  filter: grayscale(100%);
  transition: filter .6s;
}

.ngg-gallery-thumbnail a:hover img {
  filter: grayscale(0%);
}

.sl-overlay {
  background-color: #1e1e1e !important;
  opacity: .9 !important;
}

.sl-wrapper .sl-navigation button {
  color: #000000 !important;
}

.sl-wrapper .sl-close,
.sl-wrapper .sl-counter {
  color: #000000 !important;
}

.sl-wrapper .sl-image .sl-caption {
  display: none !important;
}

.ngg-navigation a, .ngg-navigation span {
  font-size: 1.1rem;
  display: inline-block !important;
  vertical-align: middle !important;
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
  line-height: calc(2rem - 2px) !important;
  padding: 0 !important;
  text-align: center !important;
  background-color: transparent !important;
  border: solid 1px #511575 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.ngg-navigation span {
  background-color: #511575 !important;
}

.tribe-events-single {
  text-align: center;
}

.tribe-events-single h1 {
  display: inline-block;
  border: solid 1px #000000;
  border-width: 2px 0;
  padding: .5rem 0;
}

body {
  margin: 0;
  color: #000000;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  -webkit-smoothing: antialiased;
  -moz-osx-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body.admin-bar .block-header,
body.admin-bar .block-header,
body.admin-bar .block-mobile-nav {
  top: 32px;
}

a {
  text-decoration: none;
  outline-width: 0;
}

a:hover {
  text-decoration: none;
  outline-width: 0;
}

a:active {
  text-decoration: none;
  outline-width: 0;
}

a:visited {
  text-decoration: none;
  outline-width: 0;
}

a:focus {
  text-decoration: none;
  outline-width: 0;
}

button,
input,
select,
textarea {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:hover,
input:hover,
select:hover,
textarea:hover {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:active,
input:active,
select:active,
textarea:active {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:visited,
input:visited,
select:visited,
textarea:visited {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

.h1,
h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.h2,
h2 {
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.h3,
h3 {
  font-size: 1.3rem;
  line-height: 1.2;
}

.h4,
h4 {
  font-size: 1.3rem;
}

.h5,
h5 {
  font-size: 1.2rem;
}

.h6,
h6 {
  font-size: 1rem;
}

a {
  color: #511575;
}

a:hover {
  color: inherit;
}

p {
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

small {
  font-size: 90%;
  font-weight: inherit;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

label {
  font-weight: 400;
}

button {
  color: inherit;
  font-weight: inherit;
}

nav {
  display: block;
}

img {
  max-width: 100%;
}

.block-header {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  transition: .4s;
}

.block-header.expanded {
  right: 260px;
}

@media (min-width: 1200px) {
  .block-header.expanded {
    right: 0;
  }
}

.block-header .logo-block {
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .block-header .logo-block {
    padding: 0;
  }
}

.block-header .logo-block img {
  width: auto;
  height: 18px;
}

.block-header .nav-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.block-header .nav-block ul li {
  margin: 0 10px;
  padding: 0;
  position: relative;
}

.block-header .nav-block ul li:hover ul {
  opacity: 1;
  visibility: visible;
}

.block-header .nav-block ul li ul {
  width: 14rem;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ebebeb;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
  padding: .5rem 0;
}

.block-header .nav-block ul li ul li {
  margin: 0;
}

.block-header .nav-block ul li ul li a {
  font-size: 1rem;
  font-weight: 300;
  padding: .5rem 1rem;
}

.block-header .nav-block ul li ul li a::after {
  display: none;
}

.block-header .nav-block ul li ul li a:hover {
  background-color: #ffffff;
}

.block-header .nav-block a {
  position: relative;
  display: block;
  color: #000000;
  padding: 1.5rem 0;
  font-weight: 400;
  transition: .4s;
}

.block-header .nav-block a::after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #511575;
  transform-origin: right top;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
}

.block-header .nav-block a:hover {
  color: #511575;
}

.block-header .nav-block a:hover::after {
  transform-origin: left top;
  transform: scale(1);
}

.block-header .search-block {
  position: relative;
  width: 8rem;
}

.block-header .search-block input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: #000000;
}

.block-header .search-block input::placeholder {
  color: #000000;
}

.block-header .search-block button {
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.block-header .search-block button svg {
  width: 80%;
  height: 80%;
  margin: 10%;
}

.block-header .search-block button svg path {
  fill: #000000;
  transition: .4s;
}

.block-header .search-block button:hover svg path {
  fill: #511575;
}

.block-header .btn-menu {
  position: relative;
  width: 3rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: right;
  background: none;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}

.block-header .btn-menu.active span {
  transform: translateY(-90px);
  opacity: 0;
}

.block-header .btn-menu.active span:nth-child(2) {
  width: 100%;
  transform: translateY(0);
  opacity: 1;
}

.block-header .btn-menu.active span:nth-child(2)::before {
  transform: rotate(45deg);
}

.block-header .btn-menu.active span:nth-child(2)::after {
  transform: rotate(-45deg);
}

.block-header .btn-menu.active span:nth-child(3) {
  transform: translateY(90px);
}

.block-header .btn-menu div {
  width: 1.4rem;
  margin: .5rem auto;
}

.block-header .btn-menu div::after {
  display: block;
  clear: both;
  content: '';
}

.block-header .btn-menu span {
  display: block;
  float: right;
  position: relative;
  width: 100%;
  height: 2px;
  margin: 2px 0;
  border-radius: 3px;
  background-color: #000000;
}

.block-header .btn-menu span:nth-child(2) {
  background-color: transparent;
}

.block-header .btn-menu span:nth-child(2)::before, .block-header .btn-menu span:nth-child(2)::after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #000000;
  transition: .4s;
}

.block-banner {
  position: relative;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .block-banner {
    height: 100vh;
    height: calc(100vh - 5rem);
    margin-left: 5vw;
    margin-bottom: 5rem;
  }
}

.block-banner .image-block {
  width: 100%;
  height: 60vh;
  position: relative;
  z-index: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .block-banner .image-block {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.block-banner .owl-carousel,
.block-banner .owl-stage-outer,
.block-banner .owl-stage,
.block-banner .owl-item,
.block-banner .item {
  height: 100%;
}

.block-banner .item {
  width: 100%;
  height: 100%;
}

.block-banner figure {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  z-index: 0;
}

.block-banner figure img {
  object-position: left top;
}

.block-banner article {
  position: relative;
  z-index: 1;
  width: 30rem;
  max-width: 100%;
  margin-top: 15px;
  padding-bottom: 5vh;
  background-color: #511575;
  color: #ffffff;
}

@media (min-width: 768px) {
  .block-banner article {
    margin-top: 0;
    bottom: -5rem;
  }
}

.block-banner .block-line::after {
  background-color: #ffffff;
}

.block-banner .text-block {
  padding: 2rem;
}

.block-banner h1 {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 3vh;
}

.block-banner p {
  font-weight: 300;
  margin-bottom: 3vh;
}

.block-banner .btn, .block-banner .form-block div.wpforms-container-full .wpforms-form button[type=submit], .form-block div.wpforms-container-full .wpforms-form .block-banner button[type=submit],
.block-banner .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit], .block-wysiwyg div.wpforms-container-full .wpforms-form .block-banner button[type=submit], .block-banner .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form .block-banner button[type=submit] {
  padding: .6rem 2rem;
}

.block-banner footer {
  position: absolute;
  bottom: -3rem;
  left: 15px;
}

.block-banner footer .counter {
  font-size: .9rem;
  margin: 0 2rem 0 1rem;
}

.block-banner footer button {
  border: none;
  background-color: transparent;
  color: #000000;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: .4s;
}

.block-banner footer button.disabled svg polygon {
  fill: #cccccc;
}

.block-banner footer button.disabled:hover svg polygon {
  fill: #cccccc;
}

.block-banner footer button svg {
  width: 60%;
  height: 60%;
  display: block;
  margin: 20%;
}

.block-banner footer button svg polygon {
  fill: #000000;
  transition: .4s;
}

.block-banner footer button:hover {
  border-color: #511575;
}

.block-banner footer button:hover svg polygon {
  fill: #511575;
}

.block-main h2 {
  color: #d0bcdc;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

@media (min-width: 575px) {
  .block-main h2 {
    font-size: 4rem;
  }
}

@media (min-width: 768px) {
  .block-main h2 {
    font-size: 4.5rem;
  }
}

@media (min-width: 992px) {
  .block-main h2 {
    font-size: 5rem;
  }
}

.block-main h6 {
  text-transform: uppercase;
}

.block-main article {
  max-width: 30rem;
  font-size: 1rem;
  font-weight: 300;
}

@media (min-width: 575px) {
  .block-main article {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  .block-main article {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  .block-main article {
    font-size: 1.3rem;
  }
}

.block-archive {
  display: block;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .block-archive {
    margin-bottom: 0;
  }
}

.block-archive .data-block {
  display: block;
  color: #000000;
  position: relative;
}

.block-archive .data-block:hover figure {
  transform: scaleX(0.9);
}

.block-archive .data-block:hover figure img {
  transform: scaleX(1.2) scaleY(1.1);
}

.block-archive figure {
  width: 100%;
  height: 0;
  margin: 0;
  padding-bottom: 120%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  transform: scaleX(1);
  transition: .4s;
}

.block-archive figure img {
  transform: scaleX(1) scaleY(1);
  transition: .4s;
}

.block-archive figcaption {
  width: 80%;
  background-color: #ffffff;
  padding: 2rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .block-archive figcaption {
    width: 290px;
  }
}

.block-archive p {
  font-size: 1rem;
}

.block-section-books .data-block {
  position: relative;
}

@media (min-width: 768px) {
  .block-section-books .data-block {
    padding-left: 20rem;
    margin-right: -20rem;
  }
}

@media (min-width: 768px) {
  .block-section-books header {
    width: 20rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.block-section-books h2 {
  width: 70%;
  margin: 0 0 1rem;
  mask: url("../images/resources-h.svg");
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #511575;
  background-image: url("../images/resources.jpg");
}

@media (min-width: 768px) {
  .block-section-books h2 {
    width: 4.4rem;
    margin: 0 auto;
    mask: url("../images/resources.svg");
  }
}

.block-section-books h2 img {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  visibility: hidden;
}

.block-section-books nav {
  margin-bottom: 1rem;
}

.block-section-books nav button {
  border: solid 1px #511575;
  background-color: transparent;
  color: #511575;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: .5rem;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: .4s;
}

.block-section-books nav button svg {
  width: 60%;
  height: 60%;
  display: block;
  margin: 20%;
}

.block-section-books nav button svg polygon {
  fill: #511575;
  transition: .4s;
}

.block-section-books nav button:hover {
  border-color: #000000;
}

.block-section-books nav button:hover svg polygon {
  fill: #000000;
}

.owl-item.active .block-book .data-text figcaption {
  transform: translateY(0);
}

.block-book .data-text {
  display: block;
  color: #000000;
  position: relative;
  padding-bottom: 2rem;
}

.block-book .data-text:hover figure {
  transform: scaleX(0.9);
}

.block-book .data-text:hover figure img {
  transform: scaleX(1.1) scaleY(1);
}

.block-book figure {
  width: 100%;
  height: 0;
  margin: 0;
  padding-bottom: 140%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  transform: scaleX(1);
  transition: .4s;
}

.block-book figure img {
  transform: scaleX(1) scaleY(1);
  transition: .4s;
}

.block-book figcaption {
  width: 70%;
  background-color: #511575;
  color: #ffffff;
  text-align: center;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  transform: translateY(100%);
  transition: .4s;
  transition-delay: .4s;
}

.block-book h3 {
  font-size: 1.2rem;
  margin: 0;
}

.block-book p {
  text-transform: uppercase;
  font-size: .8rem;
}

.block-section-poems h2 {
  font-size: 3rem;
  font-weight: 700;
  width: 14rem;
  display: inline-block;
}

.block-section-poems h4 {
  margin-bottom: 0;
}

.block-section-poems blockquote {
  padding: 1rem 0 0 2rem;
  margin-left: -2rem;
  font-style: italic;
  position: relative;
}

.block-section-poems blockquote::before {
  content: "“";
  font-size: 4rem;
  font-style: normal;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  color: #511575;
}

.block-section-poems article {
  padding-left: 2rem;
}

.block-section-poems .btn, .block-section-poems .form-block div.wpforms-container-full .wpforms-form button[type=submit], .form-block div.wpforms-container-full .wpforms-form .block-section-poems button[type=submit],
.block-section-poems .block-wysiwyg div.wpforms-container-full .wpforms-form button[type=submit], .block-wysiwyg div.wpforms-container-full .wpforms-form .block-section-poems button[type=submit], .block-section-poems .form-contact-block div.wpforms-container-full .wpforms-form button[type=submit], .form-contact-block div.wpforms-container-full .wpforms-form .block-section-poems button[type=submit] {
  margin-left: 2rem;
}

.block-section-poems .owl-dots {
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.block-section-poems .owl-dots button {
  width: 12px;
  height: 12px;
  margin: 1px;
  padding: 0;
  background-color: transparent;
  border: solid 2px #511575;
  border-radius: 12px;
  display: inline-block;
  vertical-align: middle;
  transition: .4s;
  transform: scale(0.9);
}

.block-section-poems .owl-dots button.active {
  width: 24px;
  transform: scale(1);
}

.block-section-endo {
  background-color: #511575;
  color: #ffffff;
  text-align: center;
}

.block-section-endo .data-block {
  max-width: 800px;
  margin: auto;
}

.block-section-endo .data-block img {
  width: auto;
  max-width: initial;
  height: 10rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16rem;
  z-index: -1;
  margin: auto;
}

.block-section-endo h2 {
  color: #ffffff;
}

.block-section-endo .block-line {
  margin: 1rem 0 2rem;
}

.block-section-endo .block-line::after {
  background-color: #ffffff;
}

.block-section-endo .data-text {
  max-width: 700px;
  padding: 5rem 2rem 5rem 8rem;
}

.block-section-endo article {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 600;
}

.block-section-endo footer {
  margin: 2rem 0;
}

.block-section-endo .owl-dots {
  margin-bottom: 3rem;
}

.block-section-endo .owl-dots button {
  width: 12px;
  height: 12px;
  margin: 1px;
  padding: 0;
  background-color: transparent;
  border: solid 2px #ffffff;
  border-radius: 12px;
  display: inline-block;
  vertical-align: middle;
  transition: .4s;
  transform: scale(0.9);
}

.block-section-endo .owl-dots button.active {
  width: 24px;
  transform: scale(1);
}

.block-blog {
  width: 100%;
  display: block;
  color: #000000;
  position: relative;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .block-blog {
    margin-bottom: 0;
  }
}

.block-blog:hover figure {
  transform: scaleX(0.9);
}

.block-blog:hover figure img {
  transform: scaleX(1.1) scaleY(1);
}

.block-blog figure {
  width: 100%;
  height: 0;
  margin: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  transform: scaleX(1);
  transition: .4s;
}

.block-blog figure a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
}

.block-blog figure img {
  transform: scaleX(1) scaleY(1);
  transition: .4s;
}

.block-blog h3 {
  background-color: #511575;
  color: #ffffff;
  font-size: 1rem;
  position: absolute;
  left: 2rem;
  right: 2rem;
  bottom: -1rem;
  margin: auto;
  padding: 1rem;
  text-align: center;
}

.block-footer {
  background-color: #000000;
  color: #ffffff;
  padding: 3rem 0 4rem;
  font-size: .9rem;
}

.block-footer a {
  color: #cccccc;
  transition: .4s;
}

.block-footer a:hover {
  color: #ffffff;
}

.block-footer p {
  color: #cccccc;
}

.block-footer h5 {
  font-size: .9rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.block-footer .item {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .block-footer .item {
    margin-top: 0;
  }
}

.block-footer .icon-row {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.block-footer .icon-row .icon-block {
  position: absolute;
  top: 3px;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.block-footer .icon-row .icon-block svg {
  display: block;
  width: 100%;
  height: 100%;
}

.block-footer .icon-row .icon-block svg path {
  fill: #cccccc;
}

.block-footer .newsletter-block {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .block-footer .newsletter-block {
    margin-bottom: 0;
  }
}

.block-footer .newsletter-block .data-row {
  margin-left: -10px;
  margin-right: -10px;
}

.block-footer .newsletter-block .data-col {
  float: left;
  position: relative;
  min-height: 1px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.block-footer .newsletter-block .data-half {
  width: 100%;
}

@media (min-width: 575px) {
  .block-footer .newsletter-block .data-half {
    width: 50%;
  }
}

.block-footer .newsletter-block .data-action {
  position: relative;
  padding-right: 3rem;
}

.block-footer .newsletter-block p {
  margin-bottom: 1.5rem;
}

.block-footer .newsletter-block .data-block {
  position: relative;
}

.block-footer .newsletter-block input {
  width: 100%;
  height: 2.2rem;
  padding-left: 1rem;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;
  -webkit-appearance: none;
  appearance: none;
}

.block-footer .newsletter-block button {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.2rem;
  height: 2.2rem;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: solid 1px #ffffff;
  transition: .4s;
}

.block-footer .newsletter-block button:hover {
  border-color: #ffffff;
  background-color: #ffffff;
}

.block-footer .newsletter-block button svg {
  width: 60%;
  height: 60%;
  margin: 20%;
  display: block;
}

.block-footer .newsletter-block button svg polygon {
  fill: #000000;
}

.block-footer .copy-block {
  border-top: solid 1px #cccccc;
  margin-top: 2rem;
  padding-top: 1rem;
}

.block-footer nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.block-footer nav ul li {
  margin: 0 0 .5rem;
  padding: 0;
}

.block-footer nav a {
  text-transform: uppercase;
  font-size: .8rem;
}

.owl-carousel .block-article {
  margin-bottom: 0;
}

.block-article {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  display: block;
  color: #000000;
  text-align: left;
  transition: 0.4s;
}

.block-article .image-block {
  position: relative;
  padding-bottom: 5rem;
}

.block-article figure {
  width: 100%;
  height: 0;
  margin: 0;
  padding-bottom: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.block-article .caption-block {
  width: 90%;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

.block-article footer {
  margin-left: 10%;
  padding-left: 1rem;
}

.block-article h3 {
  font-weight: 600;
}

.block-article p {
  margin: 0;
  font-size: 1rem;
}

.block-section-prof .data-block {
  position: relative;
}

@media (min-width: 768px) {
  .block-section-prof .data-block {
    padding: 0 3rem;
    margin: 0 -3rem;
  }
}

.block-section-prof h2 {
  margin-bottom: 2rem;
}

.block-section-counter {
  background-color: #511575;
  color: #ffffff;
  text-align: center;
}

.block-section-counter h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.block-section-counter .block-line {
  margin-bottom: 2rem;
}

.block-section-counter .block-line::after {
  background-color: #ffffff;
}

.block-section-counter h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.block-section-counter .item {
  padding: 0 1rem 2rem 1rem;
  line-height: 1;
  letter-spacing: 2px;
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .block-section-counter .item {
    padding: 0 2rem 2rem 2rem;
    letter-spacing: 3px;
    font-size: 1rem;
  }
}

.block-section-counter .item div {
  display: block;
  font-size: 2.5rem;
  font-weight: 600;
}

@media (min-width: 575px) {
  .block-section-counter .item div {
    font-size: 3rem;
  }
}

@media (min-width: 768px) {
  .block-section-counter .item div {
    font-size: 5rem;
  }
}

@media (min-width: 992px) {
  .block-section-counter .item div {
    font-size: 6rem;
  }
}

.block-page-header {
  text-align: center;
}

.block-page-header header {
  display: inline-block;
  border: solid 1px #000000;
  border-width: 2px 0;
  padding: .5rem 0;
}

.block-page-header h1 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
}

.block-page-header p {
  margin: .5rem 0 0;
}

.block-section-article h2 {
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  font-size: 2rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .block-section-article h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .block-section-article h2 {
    font-size: 3rem;
  }
}

body.single-post .main-block {
  padding-top: 0;
}

body.single-post .block-gb-parallaximage {
  padding: 0;
}

.block-page-article h1 {
  text-align: center;
}

.block-page-article figure img {
  width: 100%;
}

.block-page-article .block-wysiwyg p:first-child::first-letter {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1;
}

.block-page-contact a {
  color: #000000;
}

.block-page-contact header ul {
  list-style: none;
}

.block-page-contact header ul li {
  margin: 0 1rem;
}

.block-page-contact .icon-row {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.block-page-contact .icon-row .icon-block {
  position: absolute;
  top: 5px;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.block-page-contact .icon-row .icon-block svg {
  display: block;
  width: 100%;
  height: 100%;
}

.block-page-contact .icon-row .icon-block svg path {
  fill: #511575;
}

.background-black {
  background-color: #000000;
  color: #ffffff;
}

.background-black h1, .background-black h2, .background-black h3, .background-black h4, .background-black h5, .background-black h6 {
  color: #ffffff;
}

.background-dark {
  background-color: #262626;
  color: #ffffff;
}

.background-dark h1, .background-dark h2, .background-dark h3, .background-dark h4, .background-dark h5, .background-dark h6 {
  color: #ffffff;
}

.background-orange {
  background-color: #ea8423;
}

.background-yellow {
  background-color: #f7d805;
}

.color-white {
  color: #ffffff;
}

.color-theme {
  color: #511575;
}

.color-dark {
  color: #262626;
}

.gap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 575px) {
  .gap {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .gap {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.gap-up {
  padding-top: 2rem;
}

@media (min-width: 575px) {
  .gap-up {
    padding-top: 3rem;
  }
}

@media (min-width: 768px) {
  .gap-up {
    padding-top: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap-up {
    padding-top: 4rem;
  }
}

.gap-down {
  padding-bottom: 2rem;
}

@media (min-width: 575px) {
  .gap-down {
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .gap-down {
    padding-bottom: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap-down {
    padding-bottom: 4rem;
  }
}
