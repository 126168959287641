@import 'variables';
.block-pagination{
  margin-top: 2rem;
  a,
  span {
    height: 2rem;
    line-height: 2rem;
    border: solid 1px $border;
    padding: 0 .5rem;
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    color: $font;
  }
}
