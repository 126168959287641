@import 'variables';

body {
  margin: 0;
  color: $font;
  @extend %SourceSans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  -webkit-smoothing: antialiased;
  -moz-osx-smoothing: grayscale;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.modal-open {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &.admin-bar {
    .block-header,
    .block-header,
    .block-mobile-nav {
      top: 32px;
    }
  }
}
@include anchor() {
  text-decoration: none;
  outline-width: 0;
}
@include input() {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %SourceSans;
  font-weight: 600;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

.h1,
h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.5rem;
  // @include vpSize(xsm) {
  //   font-size: 1.6rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.8rem;
  //   margin-bottom: 1rem;
  // }
  // @include vpSize(xlg) {
  //   font-size: 2rem;
  // }
  // @include vpSize(xxl) {
  //   font-size: 2.2rem;
  // }
}

.h2,
h2 {
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0.5rem;
  // @include vpSize(xsm) {
  //   font-size: 1.5rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.8rem;
  //   margin-bottom: 1rem;
  // }
  // @include vpSize(xlg) {
  //   font-size: 2rem;
  // }
}

.h3,
h3 {
  font-size: 1.3rem;
  line-height: 1.2;
  // @include vpSize(xsm) {
  //   font-size: 1.4rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.6rem;
  // }
  // @include vpSize(xlg) {
  //   font-size: 1.8rem;
  // }
}

.h4,
h4 {
  font-size: 1.3rem;
  // @include vpSize(xsm) {
  //   font-size: 1.5rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.7rem;
  // }
}

.h5,
h5 {
  font-size: 1.2rem;
  // @include vpSize(xsm) {
  //   font-size: 1.4rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.6rem;
  // }
}

.h6,
h6 {
  font-size: 1rem;
  // @include vpSize(xsm) {
  //   font-size: 1.3rem;
  // }
  // @include vpSize(xmd) {
  //   font-size: 1.5rem;
  // }
}

a {
  color: $theme;

  &:hover {
    color: inherit;
  }
}

p {
  margin-bottom: 1rem;

  &:last-child{
    margin-bottom: 0;
  }
}

small {
  font-size: 90%;
  font-weight: inherit;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

label {
  font-weight: 400;
}

button {
  color: inherit;
  font-weight: inherit;
}

nav {
  display: block;
}

img {
  max-width: 100%;
}
.block-header{
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  transition: .4s;

  &.expanded{
    right: 260px;
    @include vpSize(xxl){
      right: 0;
    }
  }

  .logo-block{
    padding: 1rem 0;
    @include vpSize(xmd){
      padding: 0;
    }

    img{
      width: auto;
      height: 18px;
    }
  }

  .nav-block{
    ul{
      list-style: none;
      margin: 0;
      padding: 0;

      li{
        margin: 0 10px;
        padding: 0;
        position: relative;

        &:hover{
          ul{
            opacity: 1;
            visibility: visible;
          }
        }

        ul{
          width: 14rem;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: $gray;
          opacity: 0;
          visibility: hidden;
          transition: .4s;
          padding: .5rem 0;

          li{
            margin: 0;

            a{
              font-size: 1rem;
              font-weight: 300;
              padding: .5rem 1rem;

              &::after{
                display: none;
              }

              &:hover{
                background-color: $white;
              }
            }
          }
        }
      }
    }
    a{
      position: relative;
      display: block;
      color: $font;
      padding: 1.5rem 0;
      font-weight: 400;
      transition: .4s;

      &::after{
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $theme;
        transform-origin: right top;
        transform: scaleX(0);
        transition: transform .3s cubic-bezier(.25,.46,.45,.94) 0ms;
      }

      &:hover{
        color: $theme;

        &::after{
          transform-origin: left top;
          transform: scale(1);
        }
      }
    }
  }

  .search-block{
    position: relative;
    width: 8rem;

    input{
      width: 100%;
      background-color: transparent;
      border: none;
      color: $font;

      &::placeholder{
        color: $font;
      }
    }

    button{
      width: 2rem;
      height: 2rem;
      background-color: $white;
      border: none;
      outline: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;

      svg{
        width: 80%;
        height: 80%;
        margin: 10%;

        path{
          fill: $font;
          transition: .4s;
        }
      }

      &:hover{
        svg{
          path{
            fill: $theme;
          }
        }
      }
    }
  }

  .btn-menu{
    position: relative;
    width: 3rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: right;
    background: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;

    &.active{
      span{
        transform: translateY(-90px);
        opacity: 0;
        &:nth-child(2){
          width: 100%;
          transform: translateY(0);
          opacity: 1;

          &::before{
            transform: rotate(45deg);
          }
          &::after{
            transform: rotate(-45deg);
          }
        }
        &:nth-child(3){
          transform: translateY(90px);
        }
      }
    }

    div{
      width: 1.4rem;
      margin: .5rem auto;
      @include clearfx;
    }

    span{
      display: block;
      float: right;
      position: relative;
      width: 100%;
      height: 2px;
      margin: 2px 0;
      border-radius: 3px;
      background-color: $black;

      &:nth-child(2){
        background-color: transparent;

        &::before, &::after{
          width: 100%;
          height: 100%;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color: $black;
          transition: .4s;
        }
      }
    }
  }
}
.block-banner{
  position: relative;
  margin-bottom: 2rem;
  @include vpSize(xmd){
    height: 100vh;
    height: calc(100vh - 5rem);
    margin-left: 5vw;
    margin-bottom: 5rem;
  }

  .image-block{
    width: 100%;
    height: 60vh;
    position: relative;
    z-index: 0;
    margin: 0;
    @include vpSize(xmd){
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .owl-carousel,
  .owl-stage-outer,
  .owl-stage,
  .owl-item,
  .item{
    height: 100%;
  }
  .item{
    width: 100%;
    height: 100%;
  }

  figure{
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
    z-index: 0;

    img{
      @extend %ObjectCover;
      object-position: left top;
    }
  }

  article{
    position: relative;
    z-index: 1;
    width: 30rem;
    max-width: 100%;
    margin-top: 15px;
    padding-bottom: 5vh;
    background-color: $theme;
    color: $white;
    @include vpSize(xmd){
      margin-top: 0;
      bottom: -5rem;
    }
  }
  .block-line{
    &::after{
      background-color: $white;
    }
  }
  .text-block{
    padding: 2rem;
  }
  h1{
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 3vh;
  }
  p{
    font-weight: 300;
    margin-bottom: 3vh;
  }
  .btn{
    padding: .6rem 2rem;
  }

  footer{
    position: absolute;
    bottom: -3rem;
    left: 15px;

    .counter{
      font-size: .9rem;
      margin: 0 2rem 0 1rem;
    }

    button{
      border: none;
      background-color: transparent;
      color: $font;
      outline: none;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      -webkit-appearance: none;
      appearance: none;
      transition: .4s;

      &.disabled{
        svg{
          polygon{
            fill: $literfont;
          }
        }

        &:hover{
          svg{
            polygon{
              fill: $literfont;
            }
          }
        }
      }

      svg{
        width: 60%;
        height: 60%;
        display: block;
        margin: 20%;

        polygon{
          fill: $font;
          transition: .4s;
        }
      }

      &:hover{
        border-color: $theme;

        svg{
          polygon{
            fill: $theme;
          }
        }
      }
    }
  }
}
.block-main{
  h2{
    color: $litertheme;
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    @include vpSize(xsm){
      font-size: 4rem;
    }
    @include vpSize(xmd){
      font-size: 4.5rem;
    }
    @include vpSize(xlg){
      font-size: 5rem;
    }
  }
  h6{
    text-transform: uppercase;
  }
  article{
    max-width: 30rem;
    font-size: 1rem;
    font-weight: 300;
    @include vpSize(xsm){
      font-size: 1.1rem;
    }
    @include vpSize(xmd){
      font-size: 1.2rem;
    }
    @include vpSize(xlg){
      font-size: 1.3rem;
    }
  }
}
.block-archive{
  display: block;
  margin-bottom: 2rem;
  @include vpSize(xmd){
    margin-bottom: 0;
  }
  .data-block{
    display: block;
    color: $font;
    position: relative;

    &:hover{
      figure{
        transform: scaleX(.9);

        img{
          transform: scaleX(1.2) scaleY(1.1);
        }
      }
    }
  }

  figure{
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 120%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transform: scaleX(1);
    transition: .4s;

    img{
      @extend %ObjectCover;
      transform: scaleX(1) scaleY(1);
      transition: .4s;
    }
  }

  figcaption{
    width: 80%;
    background-color: $white;
    padding: 2rem;
    position: absolute;
    right: 0;
    bottom: 0;
    @include vpSize(xmd){
      width: 290px;
    }
  }
  p{
    font-size: 1rem;
  }
}
.block-section-books{
  .data-block{
    position: relative;
    @include vpSize(xmd){
      padding-left: 20rem;
      margin-right: -20rem;
    }
  }
  header{
    @include vpSize(xmd){
      width: 20rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  h2{
    width: 70%;
    margin: 0 0 1rem;
    mask: url('../images/resources-h.svg');
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: $theme;
    background-image: url('../images/resources.jpg');
    @include vpSize(xmd){
      width: 4.4rem;
      margin: 0 auto;
      mask: url('../images/resources.svg');
    }

    img{
      width: 100%;
      height: auto;
      display: block;
      opacity: 0;
      visibility: hidden;
    }
  }

  nav{
    margin-bottom: 1rem;

    button{
      border: solid 1px $theme;
      background-color: transparent;
      color: $theme;
      outline: none;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: .5rem;
      padding: 0;
      -webkit-appearance: none;
      appearance: none;
      transition: .4s;

      svg{
        width: 60%;
        height: 60%;
        display: block;
        margin: 20%;

        polygon{
          fill: $theme;
          transition: .4s;
        }
      }

      &:hover{
        border-color: $black;

        svg{
          polygon{
            fill: $black;
          }
        }
      }
    }
  }
}
.owl-item.active{
  .block-book{
    .data-text{
      figcaption{
        transform: translateY(0);
      }
    }
  }
}
.block-book{
  .data-text{
    display: block;
    color: $font;
    position: relative;
    padding-bottom: 2rem;

    &:hover{
      figure{
        transform: scaleX(.9);

        img{
          transform: scaleX(1.1) scaleY(1);
        }
      }
    }
  }

  figure{
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 140%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transform: scaleX(1);
    transition: .4s;

    img{
      @extend %ObjectCover;
      transform: scaleX(1) scaleY(1);
      transition: .4s;
    }
  }

  figcaption{
    width: 70%;
    background-color: $theme;
    color: $white;
    text-align: center;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: auto;
    transform: translateY(100%);
    transition: .4s;
    transition-delay: .4s;
  }
  h3{
    font-size: 1.2rem;
    margin: 0;
  }
  p{
    text-transform: uppercase;
    font-size: .8rem;
  }
}
.block-section-poems{
  h2{
    font-size: 3rem;
    font-weight: 700;
    width: 14rem;
    display: inline-block;
  }
  h4{
    margin-bottom: 0;
  }
  blockquote{
    padding: 1rem 0 0 2rem;
    margin-left: -2rem;
    font-style: italic;
    position: relative;

    &::before{
      content: "“";
      font-size: 4rem;
      font-style: normal;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
      color: $theme;
    }
  }
  article{
    padding-left: 2rem;
  }
  .btn{
    margin-left: 2rem;
  }
  .owl-dots{
    margin-left: 2rem;
    margin-bottom: 2rem;

    button{
      width: 12px;
      height: 12px;
      margin: 1px;
      padding: 0;
      background-color: transparent;
      border: solid 2px $theme;
      border-radius: 12px;
      display: inline-block;
      vertical-align: middle;
      transition: .4s;
      transform: scale(.9);

      &.active{
        width: 24px;
        transform: scale(1);
      }
    }
  }
}
.block-section-endo{
  background-color: $theme;
  color: $white;
  text-align: center;

  .data-block{
    max-width: 800px;
    margin: auto;

    img{
      width: auto;
      max-width: initial;
      height: 10rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -16rem;
      z-index: -1;
      margin: auto;
    }
  }
  h2{
    color: $white;
  }
  .block-line{
    margin: 1rem 0 2rem;
    &::after{
      background-color: $white;
    }
  }
  .data-text{
    max-width: 700px;
    padding: 5rem 2rem 5rem 8rem;
  }
  article{
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 600;
  }
  footer{
    margin: 2rem 0;
  }

  .owl-dots{
    margin-bottom: 3rem;

    button{
      width: 12px;
      height: 12px;
      margin: 1px;
      padding: 0;
      background-color: transparent;
      border: solid 2px $white;
      border-radius: 12px;
      display: inline-block;
      vertical-align: middle;
      transition: .4s;
      transform: scale(.9);

      &.active{
        width: 24px;
        transform: scale(1);
      }
    }
  }
}
.block-blog{
  width: 100%;
  display: block;
  color: $font;
  position: relative;
  margin-bottom: 2rem;
  @include vpSize(xmd){
    margin-bottom: 0;
  }

  &:hover{
    figure{
      transform: scaleX(.9);

      img{
        transform: scaleX(1.1) scaleY(1);
      }
    }
  }

  figure{
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    transform: scaleX(1);
    transition: .4s;

    a{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
    }

    img{
      @extend %ObjectCover;
      transform: scaleX(1) scaleY(1);
      transition: .4s;
    }
  }
  h3{
    background-color: $theme;
    color: $white;
    font-size: 1rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: -1rem;
    margin: auto;
    padding: 1rem;
    text-align: center;
  }
}
.block-footer{
  background-color: $black;
  color: $white;
  padding: 3rem 0 4rem;
  font-size: .9rem;

  a{
    color: $literfont;
    transition: .4s;

    &:hover{
      color: $white;
    }
  }
  p{
    color: $literfont;
  }
  h5{
    font-size: .9rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .item{
    margin-top: 2rem;
    @include vpSize(xmd){
      margin-top: 0;
    }
  }

  .icon-row{
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;

    .icon-block{
      position: absolute;
      top: 3px;
      left: 0;
      width: 1.2rem;
      height: 1.2rem;

      svg{
        display: block;
        width: 100%;
        height: 100%;

        path{
          fill: $literfont;
        }
      }
    }
  }

  .newsletter-block{
    margin-bottom: 2rem;
    @include vpSize(xmd){
      margin-bottom: 0;
    }

    .data-row{
      margin-left: -10px;
      margin-right: -10px;
    }
    .data-col{
      float: left;
      position: relative;
      min-height: 1px;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .data-half{
      width: 100%;
      @include vpSize(xsm){
        width: 50%;
      }
    }
    .data-action{
      position: relative;
      padding-right: 3rem;
    }

    p{
      margin-bottom: 1.5rem;
    }
    .data-block{
      position: relative;
    }
    input{
      width: 100%;
      height: 2.2rem;
      padding-left: 1rem;
      border: solid 1px $white;
      background-color: transparent;
      color: $white;
      -webkit-appearance: none;
      appearance: none;
    }
    button{
      position: absolute;
      top: 0;
      right: 0;
      width: 2.2rem;
      height: 2.2rem;
      padding: 0;
      -webkit-appearance: none;
      appearance: none;
      background-color: $white;
      border: solid 1px $white;
      transition: .4s;

      &:hover{
        border-color: $white;
        background-color: $white;
      }

      svg{
        width: 60%;
        height: 60%;
        margin: 20%;
        display: block;

        polygon{
          fill: $black;
        }
      }
    }
  }
  .copy-block{
    border-top: solid 1px $literfont;
    margin-top: 2rem;
    padding-top: 1rem;
  }
  nav{
    ul{
      list-style: none;
      margin: 0;
      padding: 0;

      li{
        margin: 0 0 .5rem;
        padding: 0;
      }
    }
    a{
      text-transform: uppercase;
      font-size: .8rem;
    }
  }
}
.owl-carousel{
  .block-article{
    margin-bottom: 0;
  }
}
.block-article {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  display: block;
  color: $font;
  text-align: left;
  transition: 0.4s;

  .image-block{
    position: relative;
    padding-bottom: 5rem;
  }

  figure {
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;

    img{
      @extend %ObjectCover;
    }
  }

  .caption-block{
    width: 90%;
    background-color: $white;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
  footer{
    margin-left: 10%;
    padding-left: 1rem;
  }

  h3 {
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 1rem;
  }
}
.block-section-prof{
  .data-block{
    position: relative;
    @include vpSize(xmd){
      padding: 0 3rem;
      margin: 0 -3rem;
    }
  }
  h2{
    margin-bottom: 2rem;
  }
}
.block-section-counter{
  background-color: $theme;
  color: $white;
  text-align: center;

  h2{
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .block-line{
    margin-bottom: 2rem;

    &::after{
      background-color: $white;
    }
  }

  h5{
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .item{
    padding: 0 1rem 2rem 1rem;
    line-height: 1;
    letter-spacing: 2px;
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    @include vpSize(xmd){
      padding: 0 2rem 2rem 2rem;
      letter-spacing: 3px;
      font-size: 1rem;
    }

    div{
      display: block;
      font-size: 2.5rem;
      font-weight: 600;
      @include vpSize(xsm){
        font-size: 3rem;
      }
      @include vpSize(xmd){
        font-size: 5rem;
      }
      @include vpSize(xlg){
        font-size: 6rem;
      }
    }
  }
}
.block-page-header{
  text-align: center;

  header{
    display: inline-block;
    border: solid 1px $black;
    border-width: 2px 0;
    padding: .5rem 0;
  }
  h1{
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
  }
  p{
    margin: .5rem 0 0;
  }
}
.block-section-article{
  h2{
    line-height: 1;
    text-transform: uppercase;
    color: $font;
    font-size: 2rem;
    font-weight: 700;
    @include vpSize(xmd){
      font-size: 2.5rem;
    }
    @include vpSize(xlg){
      font-size: 3rem;
    }
  }
}
body{
  &.single-post{
    .main-block{
      padding-top: 0;
    }
    .block-gb-parallaximage{
      padding: 0;
    }
  }
}
.block-page-article{
  h1{
    text-align: center;
  }
  figure{
    img{
      width: 100%;
    }
  }
  .block-wysiwyg{
    p{
      &:first-child{
        &::first-letter {
          font-size: 6rem;
          font-weight: 700;
          line-height: 1;
        }
      }
    }
  }
}
.block-page-contact{
  a{
    color: $font;
  }
  header{
    ul{
      list-style: none;

      li{
        margin: 0 1rem;
      }
    }
  }
  .icon-row{
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;

    .icon-block{
      position: absolute;
      top: 5px;
      left: 0;
      width: 1.2rem;
      height: 1.2rem;

      svg{
        display: block;
        width: 100%;
        height: 100%;

        path{
          fill: $theme;
        }
      }
    }
  }
}
