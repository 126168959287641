@import 'variables';

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.mejs__overlay-button{
  position: relative;
  background-color: rgba(219, 53, 42, 0.4) !important;
  border-radius: 50%;
  background-image: none;
  outline: none;

  &::before{
    width: 60%;
    height: 60%;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(219, 53, 42, 0.8);
    transition: .4s;
  }

  &::after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent $white;
  }

  &:hover{
    &::before{
      animation: rounscale 2s ease-in-out infinite;
    }
  }
}
@keyframes rounscale{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(.9);
  }
  100%{
    transform: scale(1);
  }
}
body{
  div.wpforms-container-full{
    .wpforms-form{
      .wpforms-field-html{
        ul,ol{
          margin-left: 1rem !important;
        }
        .row{
          margin-left: -15px !important;
          margin-right: -15px !important;
        }
        .col-12{
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
    }
  }
}
.form-block,
.block-wysiwyg{
  .wpforms-container{
    text-align: left;

    .block-line{
      margin-top: 1rem;
      margin-bottom: .5rem;
      @include vpSize(xmd){
        margin-top: 0;
      }
    }
    h2{
      margin-bottom: 1.5rem !important;
    }
    .block-table{
      tr{
        &:first-child{
          td{
            color: $font !important;
          }
        }
      }
    }
  }

  div.wpforms-container-full{
    margin-bottom: 0;

    .wpforms-form{
      h2{
        font-size: 1.3rem;
        @include vpSize(xsm){
          font-size: 1.4rem;
        }
        @include vpSize(xmd){
          font-size: 1.6rem;
        }
        @include vpSize(xlg){
          font-size: 1.8rem;
        }
      }
    }
  }
  div.wpforms-container-full .wpforms-form .wpforms-field-label{
    font-size: 1.1rem;
    font-weight: 400;
    color: $white;
    margin-bottom: 1rem;
    @include vpSize(xsm){
      font-size: 1.1rem;
    }
    @include vpSize(xmd){
      font-size: 1.2rem;
    }
  }
  div.wpforms-container-full .wpforms-form{
    @extend .field-check;
  }
  div.wpforms-container-full .wpforms-form button[type=submit]{
    @extend .btn;
    @extend .btn-border;
  }
  div.wpforms-container-full .wpforms-form{
    @extend .field-block;
    margin-bottom: 0;
  }
  div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container{
    padding: 0;
  }
  div.wpforms-container-full .wpforms-form .wpforms-submit-container{
    text-align: center;
  }
  .grecaptcha-badge{
    z-index: 97;
  }
}
.form-contact-block{
  text-align: left;

  div.wpforms-container-full .wpforms-form{
    a{
      color: $white;
    }
  }
  div.wpforms-container-full{
    margin-bottom: 0;
  }
  div.wpforms-container-full .wpforms-form .wpforms-field-label{
    font-size: 1.1rem;
    font-weight: 500;
  }
  div.wpforms-container-full .wpforms-form label.wpforms-error{
    margin-top: .5rem;
  }
  div.wpforms-container-full .wpforms-form button[type=submit]{
    @extend .btn;
    @extend .btn-border;
  }
  div.wpforms-container-full .wpforms-form{
    @extend .field-block;
    margin-bottom: 0;
  }
  div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container{
    padding: 0;
  }
  div.wpforms-container-full .wpforms-form .wpforms-submit-container{
    text-align: center;
  }
  .grecaptcha-badge{
    z-index: 97;
  }
}

.pf-control-bar-button g,
.pf-control-bar-pager g{
  fill: $font;
}
#pf-pagerin{
  color: $font;
}

.block-owl-nav{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translateY(-50%);
  margin: auto;
  height: 1px;

  button{
    border: solid 1px $theme;
    background-color: transparent;
    color: $theme;
    outline: none;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    transition: .4s;

    svg{
      width: 60%;
      height: 60%;
      display: block;
      margin: 20%;

      polygon{
        fill: $theme;
        transition: .4s;
      }
    }

    &:hover{
      border-color: $black;

      svg{
        polygon{
          fill: $black;
        }
      }
    }
  }
}
.ngg-gallery-thumbnail{
  a{
    img{
      filter: grayscale(100%);
      transition: filter .6s;
    }
    &:hover{
      img{
        filter: grayscale(0%);
      }
    }
  }
}

.sl-overlay{
  background-color: #1e1e1e !important;
  opacity: .9 !important;
}
.sl-wrapper{
  .sl-navigation{
    button{
      color: $font !important;
    }
  }
  .sl-close,
  .sl-counter{
    color: $font !important;
  }
  .sl-image{
    .sl-caption{
      display: none !important;
    }
  }
}
.ngg-navigation{
  a,span{
    font-size: 1.1rem;
    display: inline-block !important;
    vertical-align: middle !important;
    width: 2rem !important;
    height: 2rem !important;
    line-height: 2rem !important;
    line-height: calc(2rem - 2px) !important;
    padding: 0 !important;
    text-align: center !important;
    background-color: transparent !important;
    border: solid 1px $theme !important;
    color: $white !important;
    border-radius: 0 !important;
  }
  span{
    background-color: $theme !important;
  }
}
.tribe-events-single{
  text-align: center;
  
  h1{
    display: inline-block;
    border: solid 1px $black;
    border-width: 2px 0;
    padding: .5rem 0;
  }
}
