@import 'variables';

.btn {
  color: $white;
  background-color: $theme;
  background-position: bottom 0 left 0;
  background-size: 202% 100%;
  border: solid 1px $theme;
  border-radius: 0;
  outline: none;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  padding: .8rem 2rem;
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  transition: all .4s ease-in-out;

  &:hover {
    color: $white;
    background-color: transparent;
    background-position: bottom 0 right 0;
    border-color: $theme;
  }

  img,svg{
    position: relative;
    bottom: 2px;
    width: auto;
    height: 12px;
    margin-left: 5px;

    &:first-child{
      margin-left: 0;
      margin-right: 5px;
    }
  }
  i{
    margin-right: 5px;
    svg{
      height: 1rem;
    }
  }
}
.btn-lite{
  color: $theme;
  background-color: $white;
  border-color: $white;

  &:hover{
    color: $theme;
    background-color: transparent;
    border-color: $white;
  }
}
.btn-radius{
  border-radius: 4rem;
}
.btn-skewed{
  transform: skew(-10deg);
}
.btn-border{
  border-color: $theme;
  background-color: transparent;
  color: $font;

  &:hover{
    border-color: $theme;
    background-color: $theme;
    color: $white;
  }
}
.btn-lite.btn-border{
  border-color: $white;
  background-color: transparent;
  color: $white;

  &:hover{
    border-color: $white;
    background-color: $white;
    color: $theme;
  }
}
.btn-primary{
  background-color: transparent;
  background-image: linear-gradient(to right, $theme 50%, transparent 50%);
  background-position: bottom 0 left 0;

  &:hover{
    background-color: transparent;
    background-position: bottom 0 right 0;
  }
}
.btn-dark{
  border-color: $dark;
  background-image: linear-gradient(to right, $dark 50%, transparent 50%);

  &:hover{
    border-color: $dark;
  }
}
.btn-arrow{
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;

  svg{
    height: 1.2rem;
    width: auto;
    display: block;
    @include vpSize(xsm){
      height: 1.4rem;
    }
    @include vpSize(xmd){
      height: 1.6rem;
    }

    polyline{
      stroke: $theme;
    }
  }
}
.arrow-black{
  svg{
    polyline{
      stroke: $font;
    }
  }
}
.btn-text{
  font-size: .9rem;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  color: $font;
  position: relative;
  transition: .4s;
}
.btn-angle{
  display: inline-block;
  vertical-align: middle;

  svg{
    height: 1.2rem;
    width: auto;
    display: block;
    @include vpSize(xsm){
      height: 1.4rem;
    }
    @include vpSize(xmd){
      height: 1.6rem;
    }

    polyline{
      stroke: $theme;
    }
  }
}
