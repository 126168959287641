@import 'variables';

%SourceSans{
  font-family: 'Source Sans Pro', sans-serif;
}
%Roboto{
  font-family: 'Roboto', sans-serif;
}
%ObjectCover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%ObjectContain {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

%sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

%fa {
  display: inline-block;
  font: normal normal normal 100%/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%auto {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

%shadow {
  box-shadow: 0 5px 45px rgba(0,0,0,0.2);
}
