@import 'variables';

.field-block{
  position: relative;
  margin-bottom: 1rem;

  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), textarea, select{
    width: 100%;
    border: solid 1px $border;
    border-radius: 0;
    padding: .5rem;
    height: 3rem;
    font-size: .9rem;
    font-weight: 400;
    position: relative;
    z-index: 1;
    background-color: transparent;
    color: $font;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: .4s;

    &::placeholder{
      color: $font;
    }
    &:focus{
      border-color: $border;
    }
  }
  select{
    background-image: url("../images/icon-sort.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 1rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    line-height: 1;
  }
  textarea,
  textarea.wpforms-field-large{
    height: 6rem;
    padding-top: 1rem;
    @include vpSize(xmd){
      height: 7rem;
    }
  }
}

.field-check{
  li{
    position: relative;
    margin-bottom: 1rem !important;

    input[type=radio],
    input[type=checkbox]{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      appearance: none;
      border: none;
      height: 20px;
      width: 20px;
    }
    label{
      padding-left: 25px;

      &::before,
      &::after{
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: .4s;
      }
      &::before{
        border: solid 1px $theme;
      }
      &::after{
        top: 3px;
        left: 3px;
        z-index: 2;
        width: 14px;
        height: 14px;
        background-color: $theme;
        transform: scale(0);
        opacity: 0;
      }

      div{
        display: inline-block;
        padding-left: .5rem;
        margin-top: -4px;

        b{
          font-size: 1rem;
        }

        span{
          font-size: .9rem;
        }
      }
    }
    input[type=radio]:checked + label,
    input[type=checkbox]:checked + label{
      &::after{
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
.wpcf7-not-valid-tip{
  font-size: .9rem;
  margin-top: 5px;
}
