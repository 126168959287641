@import 'variables';

.background-black{
  background-color: $black;
  color: $white;

  h1,h2,h3,h4,h5,h6{
    color: $white;
  }
}
.background-dark{
  background-color: $dark;
  color: $white;

  h1,h2,h3,h4,h5,h6{
    color: $white;
  }
}
.background-orange{
  background-color: $orange;
}
.background-yellow{
  background-color: $yellow;
}
.color-white{
  color: $white;
}
.color-theme{
  color: $theme;
}
.color-dark{
  color: $dark;
}

.gap {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include vpSize(xsm){
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include vpSize(xmd){
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  @include vpSize(xlg){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.gap-up {
  padding-top: 2rem;
  @include vpSize(xsm){
    padding-top: 3rem;
  }
  @include vpSize(xmd){
    padding-top: 3.5rem;
  }
  @include vpSize(xlg){
    padding-top: 4rem;
  }
}

.gap-down {
  padding-bottom: 2rem;
  @include vpSize(xsm){
    padding-bottom: 3rem;
  }
  @include vpSize(xmd){
    padding-bottom: 3.5rem;
  }
  @include vpSize(xlg){
    padding-bottom: 4rem;
  }
}
