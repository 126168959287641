@import 'variables';
.block-gb{
  .data-row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .data-row-reverse{
    flex-direction: row-reverse;
  }
  .data-col{
    width: 100%;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }
  .data-col-1,
  .data-col-2,
  .data-col-3,
  .data-col-4,
  .data-col-5,
  .data-col-6,
  .data-col-7,
  .data-col-8,
  .data-col-9,
  .data-col-10,
  .data-col-11,
  .data-col-12{
    width: 100%;
  }
  .data-col-4{
    @include vpSize(xmd){
      width: 33.333333%;
    }
  }
  .data-col-6{
    @include vpSize(xmd){
      width: 50%;
    }
  }
  .data-col-8{
    @include vpSize(xmd){
      width: 66.666666%;
    }
  }
}
.block-gb-parallaximage{
  padding: 2rem 0;

  figure{
    position: relative;
    z-index: 0;
    width: 100%;
    padding-bottom: 40%;
    overflow: hidden;
    margin: 0;

    img{
      @extend %ObjectCover;
    }
  }
}
.block-gb-textimage{
  margin-bottom: 2rem;

  &.imagealign-bottom{
    @include vpSize(xmd){
      margin-left: 5%;
      margin-right: 10%;
    }
    @include vpSize(xlg){
      margin-left: 10%;
      margin-right: 20%;
    }

    .data-image{
      margin-top: 2rem;

      figure{
        position: relative;
        left: 0;
        right: 0;
        height: auto;
        padding-bottom: 0;

        img{
          width: 100%;
          position: relative;
          object-fit: initial;
        }
      }
    }
  }
  .data-firstletter{
    p{
      &:first-child{
        &::first-letter {
          font-size: 6rem;
          font-weight: 700;
          line-height: 1;
        }
      }
    }
  }
  .data-firstletter + .data-col-right{
    margin-top: 4rem;
  }
  .data-image{
    figure{
      width: 100%;
      height: auto;
      padding-bottom: 50%;
      margin: 0 0 1.5rem;
      overflow: hidden;
      position: relative;
      z-index: 0;
      @include vpSize(xmd){
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        margin: auto;
        z-index: 0;
        width: auto;
        height: 100%;
        padding-bottom: 0;
      }

      img{
        @extend %ObjectCover;
      }
    }
  }
  .data-col-left,
  .data-col-right{
    p{
      &:last-child{
        margin: 0;
      }
    }
  }
}
.block-gb-quote{
  margin: 0 0 1rem;
  padding: 2rem 0 0 3rem;
  font-style: normal;
  position: relative;

  &::before{
    content: "“";
    font-size: 7rem;
    font-style: normal;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    color: $theme;
  }

  cite{
    &::before{
      width: 3rem;
      height: 2px;
      content: '';
      display: block;
      background-color: $theme;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
    b{
      font-style: normal;
    }
  }
}
