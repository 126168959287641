@import 'variables';

.background-page{
  background-color: $page;
  color: $font;
}
.block-inner{
  @include vpSize(xmd){
    margin-left: 5%;
    margin-right: 5%;
  }
  @include vpSize(xlg){
    margin-left: 10%;
    margin-right: 10%;
  }
}
.block-h{
  line-height: 1;
  text-transform: uppercase;
  color: $font;
  font-weight: 700;
}
.block-h1{
  font-size: 5rem;
  // @include vpSize(xsm){
  //   font-size: 6rem;
  // }
  // @include vpSize(xmd){
  //   font-size: 8rem;
  // }
  // @include vpSize(xlg){
  //   font-size: 10rem;
  // }
}
.block-h2{
  font-size: 4rem;
}
.block-h3{
  font-size: 3rem;
}
.block-h4{
  font-size: 2rem;

  span{
    color: $dark;
  }
}
.block-h5{
  font-size: 1.5rem;
}
.block-hos {
  width: 100%;
  height: 4rem;
  @include vpSize(xsm) {
    height: 6rem;
  }
}
.block-line{
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include vpSize(xmd){
    margin-top: 0;
  }

  &::after{
    display: block;
    width: 3rem;
    height: 3px;
    content: '';
    background-color: $theme;
  }
}
.line-vr{
  &::after{
    width: 3px;
    height: 3rem;
  }
}
.sr-only {
  @extend %sr-only;
}

body {
  div.wpcf7-response-output {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
}
hr{
  background-color: $border;
}

.hidden{
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999999;
}
.relative {
  position: relative;
}

.hos {
  position: absolute;
  top: 0;
  left: 0;
}
.wp-block-audio{
  audio{
    outline: none;
  }
}
.block-mobile-nav {
  background-color: $black;
  color: $font;
  font-weight: 400;
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -260px;
  z-index: 321;
  transition: 0.4s;

  &.active {
    right: 0;
  }

  .contain {
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 1rem 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  a{
    color: $literfont;
    transition: .4s;

    &:hover{
      color: $white;
    }
  }

  nav{
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: 0;
        padding: 0.5rem 0;

        ul {
          display: none;
          margin-left: 0.5rem;

          li {
            padding: 5px 0;

            i {
              top: 0.2rem;
            }

            a {
              position: relative;
              padding-left: 15px;
              text-transform: none;

              &::before {
                width: 10px;
                height: 2px;
                content: '';
                position: absolute;
                top: 0.6rem;
                left: 0;
                background-color: $white;
              }
            }
          }
        }
      }
    }
    .active {
      > i {
        &::after {
          height: 0;
        }
      }
    }
    i {
      cursor: pointer;
      display: block;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 0.2rem;
      right: 0.5rem;
      margin: auto;
      z-index: 4;

      &::after,
      &::before {
        width: 50%;
        height: 2px;
        content: '';
        background-color: $white;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: 0.4s;
      }

      &::after {
        width: 2px;
        height: 50%;
      }
    }

    a {
      display: inline-block;
      margin-right: 2rem;
      color: $white;
      font-size: .9rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .search-block {
    position: relative;
    margin-top: 1.5rem;

    input {
      width: 100%;
      height: 2.4rem;
      background-color: $white;
      border: none;
      transition: 0.4s;
      border-radius: 4rem;
      font-size: 0.9rem;
      padding-left: 1rem;
      color: $black;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      background-color: $white;
      border: none;
      border-radius: 50%;

      svg {
        width: 50%;
        height: 50%;
        margin: 25%;

        path {
          fill: $black;
        }
      }
    }
  }

  address{
    margin: auto 0 0 0;
    padding: 2rem 0 3rem;
    font-style: normal;

    p{
      border-top: solid 1px $border;
      font-weight: 300;
      padding: 1.5rem 0 0;
    }

    .icon-row{
      position: relative;
      padding-left: 2rem;
      margin-bottom: 1rem;
      font-size: 1rem;

      .icon-block{
        position: absolute;
        top: 5px;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;

        svg{
          display: block;
          width: 100%;
          height: 100%;

          path{
            fill: $literfont;
          }
        }
      }
    }

    .social-block{
      display: block;
      text-align: center;
      border-top: solid 1px $border;
      padding: 1.5rem 0 0 0;
      margin: 1.5rem 2rem 0;
      @include vpSize(xxl) {
        display: none;
      }

      a{
        color: $font;
        height: 20px;
        margin: 0 .5rem;
        font-size: 1.2rem;

        &:hover{
          color: $white;
        }
      }
    }
  }
}
.block-wrapper{
  width: 100%;
  overflow: hidden;

	&.active{
		.block-ui{
      right: 260px;
      @include vpSize(xxl){
        right: 0;
      }
    }
	}

  .block-ui{
    position: relative;
    right: 0;
  	transition: .4s;
  }
}


.block-wysiwyg{
  h2 {
    text-transform: none;
  }
}

.block-table{
  width: 100%;
  text-align: left;
  border: solid 1px $border !important;
  margin-bottom: 2rem;

  td{
    padding: 7px 10px !important;
    border-top: solid 1px $border !important;
  }

  tr{
    &:first-child{
      td{
        background-color: $dark !important;
        color: $theme !important;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
.table-summary{
  td{
    &:nth-child(1){
      width: 25%;
    }
    &:nth-child(2){
      width: 60%;
    }
    &:nth-child(3){
      width: 25%;
    }
  }
}
