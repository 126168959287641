$theme: #511575;
$font: #000000;
$litefont: #3f3f3f;
$literfont: #cccccc;
$litertheme: #d0bcdc;
$page: #e87024;
$white: #ffffff;
$black: #000000;
$dark: #262626;
$red: #ff0000;
$orange: #ea8423;
$yellow: #f7d805;
$gray: #ebebeb;
$darkgray: #303030;
$border: #dadce0;
$warning: #ffc107;
$danger: #f44336;
$success: #4caf50;
